

<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";



import { financial } from '@/api/misc'

import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";


export default {
    page: {
        title: "Positive Pay ",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },

    validations: {
       
    },


    data() {
        return {
            title: "Positive Pay",
            items: [
                {
                    text: "Accounting",
                    href: "/accounting",
                },
                {
                    text: "Positive Pay",
                    href: "/accounting/postive_pay",
                },
            ],


            dpconfig: {
                wrap: true,
                altInput: true,
                dateFormat: "Y-m",
                altFormat: "Y-m",
                ariaDateFormat: "Y-m",
            },

           
      
           
            show_error     : false,
           
            dropzoneOptions: {
                url: "/adm/accounting/positivepay/upload_outside_positve_pay_form",
                maxFilesize: 10, 
                maxFiles: 1, 
                paramName: 'form',
                thumbnailWidth: 150,
            },

            cheque_list : [],
            err_flag : false,
            err_msg  : '',
            file_url : '',
            total_count : 0,
            total_amount : 0,
        }
    },

    components: {
        Layout,
        PageHeader,
        vueDropzone: vue2Dropzone,
        

    },

    methods: {

        f(v, d=2) {
            return financial(v, d)
        },

        formSubmit() {
           
            window.open(this.file_url, '_blank')
            
        },

        onFormUploaded(evt, resp) {
            console.log(evt)
            if (resp.errCode != 0) {
                this.err_flag = true,
                this.err_msg  = resp.msg
                return 
            }
            resp.data.map(e => {
                this.cheque_list.push(e)
                if (e.err) {
                    this.err_flag = true,
                    this.err_msg  = e.err
                }
            })

            this.total_count  = resp.data.length
            this.total_amount = resp.data.reduce((a, c) => a + Number(c.cheque_amount), 0)
            this.file_url = resp.url
        },


        onReset() {
            this.cheque_list = []
        },


        getFootValue(column) {
            if (column.column == 'ChequeNumber') {
                return 'Total Count:'+ this.total_count
            } else if (column.column =='Amount') {
                return '$'+financial(this.total_amount, 2).toLocaleString()
            }
        }

       


    },

    computed: {
       
    },

    watch: {
      

    },


    created() {
       
       

    },

    mounted() {
     
       
    }
}
</script>


<template>
    <Layout>


        <PageHeader :title="title" :items="items" />
        <div class="card">
            <div class="card-body">
                <form class="needs-validation" @submit.prevent="formSubmit">
                    <div class="row">
                        <div class="col-xl-12 mb-3">
                            <vue-dropzone id="dropzone" ref="myVueDropzone" :use-custom-slot="true" :options="dropzoneOptions"   @vdropzone-success="onFormUploaded">
                                <div class="dropzone-custom-content">
                                <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                <h4>Drop form to here.</h4>
                                </div>
                            </vue-dropzone>
                        </div>

                    </div>
                    <!-- end row-->

                    <div class="row mt-5" :class="err_flag?'block':'d-none'">
                        <b-alert show variant="danger">
                            Form Data incorrect, {{ err_msg }}
                        </b-alert>
                    </div>


                    <div class="row mt-4">
                        
                        <div class="table-responsive">
                            <div class="table align-middle table-nowrap">
                                <b-table :items="cheque_list" :fields="[{ key: 'ChequeNumber' }, {key : 'Indicator'},{ key: 'Amount' },{ key: 'Date' }, { key: 'Payee' },{key : 'E'}]"
                                    responsive="sm" :per-page="cheque_list.length" class="table-check" foot-clone>

                                    <template #cell(ChequeNumber)="data">
                                        {{ data.item.cheque_number }}
                                    </template>
                                    <template #cell(Indicator)="data">
                                        {{ data.item.indicator }}
                                    </template>
                                    <template #cell(Amount)="data">
                                        ${{ f(data.item.cheque_amount, 2).toLocaleString() }}
                                    </template>
                                    <template #cell(Date)="data">
                                        {{ data.item.cheque_date }}
                                    </template>
                                    <template #cell(Payee)="data">
                                        {{ data.item.cheque_payee }}
                                    </template>

                                    <template #cell(E)="data">
                                        {{ data.item.err_msg }}
                                    </template>

                                    <template #foot()="data">
                                        <i>{{ getFootValue(data) }}</i>
                                    </template>

                                </b-table>
                            </div>
                        </div>
                       
                    </div>
                    <!-- end row-->

                    <div class="row">
                        
                        <div class="col-md-12">
                            <div class="d-flex flex-wrap gap-2 justify-content-center d-flex align-items-center">
                               
                                <b-button variant="danger" size="md" @click="onReset">Reset</b-button>
                                <b-button variant="primary" size="md" type="submit">Download File</b-button>
                            </div>
                        </div>
                        
                    </div>
                    <!-- end row-->


                </form>
            </div>
        </div>
        <!-- end card -->
    </Layout>
</template>